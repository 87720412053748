<template>
  <b-row>
    <div class="mb-32 col-12">
      <div
        class="hp-bg-dark-100 py-32 py-sm-32 px-24 px-sm-48 px-md-80 position-relative overflow-hidden hp-page-content"
        style="border-radius: 32px"
      >
        <img
          height="200"
          class="position-absolute hp-rtl-scale-x-n1 no-mobile"
          style="bottom: 10px; right: 20px"
          :src="
            require('@/assets/img/pages/games/honeyland/honeyland-logo.png')
          "
        />
        <div class="row">
          <div class="col-sm-6 col-12">
            <div class="row">
              <div class="col-12">
                <h2 class="mb-0 hp-text-color-black-0">Honeyland</h2>
              </div>
              <div class="col-12">
                <p class="h5 mb-0 mt-24 hp-text-color-black-0">
                  Honeyland is a strategy and resource management game, gaming
                  platform and decentralized exchange where developers and
                  studios build tools, games, items and more into our platform
                  that allows for infinite game and experience possibilities
                  that never get old.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-32 col-12">
     <b-tabs content-class="mt-3" justified>
        <b-tab title="About" active>
          <p class="hp-p1-body">Coming Soon</p>
        </b-tab>
        <b-tab title="Members">
          <p class="hp-p1-body">Coming Soon</p>
        </b-tab>
        <b-tab title="Register">
          <p class="hp-p1-body">Coming Soon</p>
        </b-tab>
        <b-tab title="Admin" disabled>
          <p class="hp-p1-body">I'm a disabled tab!</p>
        </b-tab>
      </b-tabs>
    </div>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BAlert, BTab } from "bootstrap-vue";

import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";

export default {
  data() {
    return {
      index: 0,
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BTab,
    Breadcrumb,
  },
  methods: {},
};
</script>

<style scoped lang="scss">
/* Mobile Style */
@media (max-width: 730px) {
  .no-mobile {
    display: none;
  }
}
</style>
